import { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { AppBar, Button, Divider, Drawer, IconButton, List, ListItem, Toolbar, Typography } from '@material-ui/core';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoginIcon from '@mui/icons-material/Login';
import PersonIcon from '@mui/icons-material/Person';
import { makeStyles } from '@material-ui/core/styles';

import Logo from './Logo';
import NavLink from './NavLink';
// import Account from './Account';
import './styles/nav.css'


const useStyles = makeStyles((theme) => ({
    navContainer: {
        display: 'flex',
        padding: '0',
        backgroundColor: '#FFF',

        width: '100%',
        color: '#000',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'space-between'

    },
    nav1: {
        width: '100%',
        margin: '0 0 0 6%',
        display: 'flex',

        [theme.breakpoints.down("sm")]: {
            display: 'none'
        },
    },
    nav2: {   
        width: '30%',
        display: 'none',

        [theme.breakpoints.down("sm")]: {
            display: 'block'
        },
    },
    drawerContainer: {
        '& .MuiDrawer-paper': { 
            width: '240px' ,
            opacity: '0.9',
        },
    },
    drawerHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 0 5% 0',

    },
    listItem: {
        justifyContent: 'flex-start',
        padding: '4% 0 4% 10%',

        '&:hover': {
            backgroundColor: '#81450A',
            color: '#FFF'
        },
    },
    buttonLink: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',

        '&:hover': {
            color: '#FFF'
        },
    },
    logobox: {
        height: '40px',
        width: '40px',
        margin: '0 6% 0 1%'
    },
    drawerLogo: {
        height: '60px',
        width: '60px',
        margin: '0 0 0 12%'
    },
    menuItem: {
        height: '100%',
        width: '150px',
        justifyContent: 'center'
    },
}));

const navLinks = [
    [
        {   
            id_Key: '1001',
            text: 'Home',
            path: '/'
        },
        {   
            id_Key: '1002',
            text: 'About',
            path: '/about'
        },
        {   
            id_Key: '1003',
            text: 'Contact',
            path: '/contact'
        },
        {   
            id_Key: '1004',
            text: 'Event',
            path: '/event'
        },
        {   
            id_Key: '1005',
            text: 'Blog',
            path: '/blog'
        }
    ],
    [
        {   
            id_Key: '2001',
            text: 'Log in',
            path: '/login',
            icon: (<LoginIcon />)
        },
        {   
            id_Key: '2002',
            text: 'Sign Up',
            path: '/signup',
            icon: (<PersonIcon />)
        }
    ]
]


let Navigation = () => {
    const classes = useStyles();
    
    
    const [anchorElNav, setAnchorElNav] = useState(false);
    

    const handleOpenNavMenu = () => {
        setAnchorElNav(true);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(false);
    };


    return (
        <AppBar position='static'>
            <Toolbar  className={classes.navContainer} disableGutters>
                <Grid className={classes.nav2}>
                    <IconButton
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <MenuRoundedIcon />
                    </IconButton>
                    <Drawer
                        component="div"
                        anchor="left"
                        open={anchorElNav}
                        onClick={handleCloseNavMenu}
                        ModalProps={{keepMounted: true }}
                        className={classes.drawerContainer}
                    >
                        <List>
                            <Grid item container className={classes.drawerHeader}>
                                <Logo className={classes.drawerLogo}/>
                                <IconButton color='inherit' onClick={handleCloseNavMenu} >
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Divider style={{ backgroundColor: '#222'}} />
                        {
                            navLinks[0].map((link) => {
                                return (
                                    <ListItem key={link.id_Key} className={classes.listItem} >
                                        <Button variant='text' href={link.path} className={classes.buttonLink}>
                                            <Typography className='listItemsText' >{link.text}</Typography>
                                        </Button>
                                    </ListItem>
                                )
                            })
                        } 
                        <Divider style={{ backgroundColor: '#333'}} />
                        {/* {
                            navLinks[1].map((link) => {
                                return (
                                    <ListItem key={link.id_Key} href={link.path} className={classes.listItem} disabled>
                                        {link.icon}
                                        <Button variant='text' href={link.path} disableElevation disabled >
                                            <Typography className='listItemsText' >{link.text}</Typography>
                                        </Button>
                                    </ListItem>
                                )
                            })
                        }  */}
                        </List> 
                    </Drawer>
                </Grid>
                <Grid className={classes.nav1}>
                    <Logo className={classes.logobox}/>
                    <NavLink  array= {navLinks[0]} />
                </Grid>
                <Grid className={classes.nav2}>
                    <Logo />
                </Grid>
                {/* <Grid className={classes.nav1} justifyContent='center'>
                    <Account  array= {navLinks[1]}  />
                </Grid>  */}
            </Toolbar>
        </AppBar>  
    )
}

export default Navigation;