import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';

import './styles/nav.css'

const useStyles = makeStyles((theme) => ({
    navLinksContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
}));


let NavLink = (props) => {
    const classes = useStyles();

    return (
        <Grid item xs={5} className={classes.navLinksContainer} >
            {
                (props.array).map((link) => {
                    return (
                        <Button 
                            key={link.id_Key}
                            // style={{ display: `${visible[link.text]}` }}
                            className='navLinks' 
                            underline="hover" 
                            color="primary" 
                            href={link.path}
                            // onClick={() => handleNavClick(link.text)}
                        >
                            {link.text}
                        </Button>
                    )
                })
            }
        </Grid>
    )
}

export default NavLink;