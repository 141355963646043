import { NAVIGATION as NAV_LINK } from './LINK'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    logoContainer:{
        height: '40px',
        width: '40px',
        margin: '0 5% 0 12%'
    }, 
}));


let Logo = (props) => {
    const classes = useStyles();

    return (
        <img 
            xs={3}
            alt="puraskaar" 
            src={NAV_LINK.baseURL + NAV_LINK.logo} 
            className={props.className || classes.logoContainer } 
        />
    )
}

export default Logo;