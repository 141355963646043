import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import Navigation from "../components/Navigation";
const Login = lazy(() => import("../components/Login"));
const Signup = lazy(() => import("../components/Signup"));
const Home = lazy(() => import("../components/Home"));
const About = lazy(() => import("../components/About"));
const Contact = lazy(() => import("../components/Contact"));
const Terms = lazy(() => import("../components/Terms"));
const Blog = lazy(() => import("../components/Blog"));
const Event = lazy(() => import("../components/Event"));

function CircularLoader() {
  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        margin: "5vh",
      }}
    >
      <Box sx={{ margin: "2vh" }}>
        <CircularProgress size={70} />
      </Box>
      <div>Loading...</div>
    </div>
  );
}

let MainRouter = () => {
  const navbarSkip = ["/login", "/signup"];
  return (
    <Router>
      {navbarSkip.includes(window.location.pathname) ? <></> : <Navigation />}
      <Suspense fallback={<CircularLoader />}>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/about" component={About} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/event" component={Event} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/blog" component={Blog} />
      </Suspense>
    </Router>
  );
};

export default MainRouter;
